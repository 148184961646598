import { defineStore } from "pinia";
import type {
  IPagination,
  IUser,
  IWishlist,
  IWishlistState,
  PaginatedResponse,
} from "./entites";
import { useNotify } from "./notifications";
import { useAPI } from "~/composables/useAPI";

export const useWishlist = defineStore("wishlist", {
  state: (): IWishlistState => ({
    wishlists: [],
    wishlist: {
      wishlistId: "",
      title: "حدث خطأ ما",
      description: "",
      slug: "",
      published: false,
      views: 0,
      owner: {
        userId: "",
        firstName: "حدث خطأ ما",
      } as IUser,
      createdAt: new Date().toLocaleString(),
      updatedAt: new Date().toLocaleString(),
      changed: false,
    },
    pagination: {
      currentPage: 1,
      perPage: 9,
      total: 0,
      totalPages: 0,
      order: "NEWEST",
    } as IPagination,
  }),
  actions: {
    async getMyWishlists(page?: number, perPage?: number) {
      page = page ?? this.pagination.currentPage;
      perPage = perPage ?? this.pagination.perPage;
      return await useAPI()
        .wishlist.getMyWishlists(page, perPage)
        .then((res: PaginatedResponse<IWishlist>) => {
          this.pagination = res as IPagination;
          this.wishlists = res.data.map((wishlist) => {
            return Object.assign(wishlist, {
              changed: false,
              loading: false,
            });
          });
        });
    },
    async getWishlistById(wishlistId: string) {
      return await useAPI()
        .wishlist.getWishlistById(wishlistId)
        .then((res) => {
          this.setWishlist(res as IWishlist);
        })
        .catch((err) => {
          return err;
        });
    },
    async getWishlistByIdForOwner(wishlistId: string): Promise<IWishlist> {
      return await useAPI()
        .wishlist.getWishlistByIdForOwner(wishlistId)
        .then((res) => {
          this.setWishlist(res as IWishlist);
          return res as IWishlist;
        })
        .catch((err) => {
          return err;
        });
    },
    async getWishlistBySlug(wishlistSlug: string) {
      return await useAPI()
        .wishlist.getWishlistBySlug(wishlistSlug)
        .then((res) => {
          this.setWishlist(res as IWishlist);
        })
        .catch((err) => {
          return err;
        });
    },
    async createWishlist(title: string) {
      this.wishlist.loading = true;
      return await useAPI()
        .wishlist.createWishlist(title)
        .then((res) => {
          this.wishlist = res as IWishlist;
          useNotify().show("notifications.wishlist.created", "", true);
          this.getMyWishlists();
        });
    },
    async updateWishlist() {
      await useAPI()
        .wishlist.updateWishlist(
          this.wishlist.wishlistId,
          this.wishlist.title,
          this.wishlist.description,
          this.wishlist.slug,
          this.wishlist.published,
        )
        .then((res) => {
          useNotify().show("notifications.wishlist.updated", "", true);
          this.setWishlist(res as IWishlist);
        });
    },
    async deleteWishlist(wishlistId: string) {
      return await useAPI()
        .wishlist.deleteWishlist(wishlistId)
        .then(() => {
          useNotify().show("notifications.wishlist.deleted", "", true);
          this.wishlists = this.wishlists.filter(
            (wishlist) => wishlist.wishlistId !== wishlistId,
          );
        });
    },
    setWishlist(wishlist: IWishlist) {
      this.wishlist = Object.assign(wishlist, {
        changed: false,
        loading: false,
      });
    },
    reset() {
      this.$reset();
    },
  },
  getters: {
    getWishlists: (state): IWishlist[] => {
      return state.wishlists;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getWishlist: (state) => {
      return state.wishlist;
    },
    findWishlistById: (state) => (wishlistId: string) => {
      return state.wishlists.find(
        (wishlist) => wishlist.wishlistId === wishlistId,
      );
    },
    findWishlistBySlug: (state) => (wishlistSlug: string) => {
      return state.wishlists.find((wishlist) => wishlist.slug === wishlistSlug);
    },
    isOwner: (state) => (currentUserId: string) => {
      return state.wishlist.owner.userId === currentUserId;
    },
  },
});
